import React, { Suspense } from 'react';
import Navbar from "./components/NavBar/navbar";
import Intro from './components/Intro/intro';
import About from "./components/About/about";
import Workshop from "./components/Workshop/workshop";
import References from "./components/References/references";
import Contact from "./components/Contact/contact";

function App() {
    return (
        <Suspense fallback="loading">
            <div className="App">
                <Navbar />
                <Intro />
                <About />
                <Workshop />
                <References />
                <Contact />
            </div>
        </Suspense>
    );
}

export default App;
