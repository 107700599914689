import React from 'react';
import {useTranslation} from "react-i18next";
import './intro.css';

const Intro = () => {
    const { t } = useTranslation();

    return (
        <section id="intro">
            <p className="introPara">{t('intro.para')}</p>
            <button className="applyWorkshopButton" onClick={() => {
                document.getElementById('applyWorkshop').scrollIntoView({behavior: 'smooth'});
            }}>{t('intro.applyWorkshopButton')}</button>
        </section>
    );
}

export default Intro;