import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguagePicker.css';
import huFlag from '../assets/hungary.png';
import usFlag from '../assets/us-icon.png';

const LanguagePicker = () => {
    const { i18n } = useTranslation();

    // Function to change the language
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    // Determine the initial language and flag
    const initialLang = i18n.language.includes('hu') ? 'hu' : 'en';
    const initialFlag = initialLang === 'hu' ? usFlag : huFlag;
    const [currentFlag, setCurrentFlag] = useState(initialFlag);
    const [currentLang, setCurrentLang] = useState(initialLang);

    // Effect to update flag when language changes
    useEffect(() => {
        setCurrentFlag(currentLang === 'hu' ? usFlag : huFlag);
    }, [currentLang]);

    // Toggle language function
    const toggleLanguage = () => {
        const newLang = currentLang === 'hu' ? 'en' : 'hu';
        setCurrentLang(newLang);
        changeLanguage(newLang);
    };

    return (
        <div className="languagePicker">
            <button className="language-toggle-button" onClick={toggleLanguage}>
                <img src={currentFlag} alt="Language" className="flag-icon" />
            </button>
        </div>
    );
};

export default LanguagePicker;
