import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo from '../../assets/logo.png';
import menu from '../../assets/menu.png';
import LanguagePicker from "../LanguagePicker";
import { useTranslation } from 'react-i18next';
import './navbar.css';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const { t } = useTranslation();

    return (
        <nav className="navbar">
            <img src={logo} alt="Logo" className='logo'/>
            <div className="desktopMenu">
                <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500}
                      className="desktopMenuListItem">{t('navbar.home')}</Link>
                <Link activeClass='active' to='about' spy={true} smooth={true} offset={-50} duration={500}
                      className="desktopMenuListItem">{t('navbar.about')}</Link>
                <Link activeClass='active' to='workshop' spy={true} smooth={true} offset={-50} duration={500}
                      className="desktopMenuListItem">{t('navbar.workshop')}</Link>
                <Link activeClass='active' to='references' spy={true} smooth={true} offset={-50} duration={500}
                      className="desktopMenuListItem">{t('navbar.references')}</Link>
                <Link activeClass='active' to='cPage' spy={true} smooth={true} offset={-50} duration={500}
                      className="desktopMenuListItem">{t('navbar.contact')}</Link>
            </div>
            <LanguagePicker />
            <img src={menu} alt="Menu" className='mobMenu' onClick={() => setShowMenu(!showMenu)}/>
            <div className="navMenu" style={{display: showMenu ? 'flex' : 'none'}}>
                <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500}
                      className="listItem" onClick={() => setShowMenu(false)}>{t('navbar.home')}</Link>
                <Link activeClass='active' to='about' spy={true} smooth={true} offset={-50} duration={500}
                      className="listItem" onClick={() => setShowMenu(false)}>{t('navbar.about')}</Link>
                <Link activeClass='active' to='workshop' spy={true} smooth={true} offset={-50} duration={500}
                      className="listItem" onClick={() => setShowMenu(false)}>{t('navbar.workshop')}</Link>
                <Link activeClass='active' to='references' spy={true} smooth={true} offset={-50} duration={500}
                      className="listItem" onClick={() => setShowMenu(false)}>{t('navbar.references')}</Link>
                <Link activeClass='active' to='contactPage' spy={true} smooth={true} offset={-50} duration={500}
                      className="listItem" onClick={() => setShowMenu(false)}>{t('navbar.contact')}</Link>
                <Link activeClass='active' to='applyWorkshop' spy={true} smooth={true} offset={-50} duration={500}
                      className="listItem" onClick={() => setShowMenu(false)}>{t('navbar.applyWorkshop')}</Link>
            </div>
        </nav>
    )
}

export default Navbar;