import React from 'react';
import profilePicture from '../../assets/about_img.JPG';
import {useTranslation} from "react-i18next";
import './about.css';

const About = () => {
    const { t } = useTranslation();

    return (
        <section id="about">
            <div className="aboutContent">
                <p className="aboutWelcome">{t('about.welcome')}</p>
                <p className="aboutPerson">{t('about.person')}</p>
                <p className="aboutPara">{t('about.paraFirst')}</p>
                <p className="aboutPara">{t('about.paraSecond')}</p>
                <p className="aboutPara">{t('about.paraThird')}</p>
            </div>
            <img src={profilePicture} alt="Profile" className="profilePicture" />
        </section>
    );
}

export default About;