import './contact.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import {useTranslation} from "react-i18next";
import facebookIcon from '../../assets/facebook-icon.png';
import instagramIcon from '../../assets/instagram.png';
import logo2 from "../../assets/logo2.png";
import trilakLogo from "../../assets/trilakLogo.jpg";
import heraLogo from "../../assets/heraLogo.jpg";

const Contact = () => {
    // State to hold the values of the inputs
    const [inputValues, setInputValues] = useState({
        from_name: '',
        from_email: '',
        from_address: '',
        from_date: '',
        from_phoneNumber: ''
    });

    const [submissionMessage, setSubmissionMessage] = useState('');
    const form = useRef();
    const { t } = useTranslation();

    // Function to handle changes in input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // You could add additional validation checks here if needed
        if (!inputValues.from_name) {
            setSubmissionMessage(`${t('applyWorkshop.missingName')} `);
            return;
        } else if (!inputValues.from_phoneNumber){
            setSubmissionMessage(`${t('applyWorkshop.missingPhone')} `);
            return;
        } else if (!inputValues.from_email){
            setSubmissionMessage(`${t('applyWorkshop.missingEmail')} `);
            return;
        } else if (!inputValues.from_address){
            setSubmissionMessage(`${t('applyWorkshop.missingAddress')} `);
            return;
        } else if (!inputValues.from_date){
            setSubmissionMessage(`${t('applyWorkshop.missingDate')} `);
            return;
        }

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_ACCESS_KEY
        )
        .then((result) => {
            console.log('Email sent successfully:', result.text);
            form.current.reset(); // Reset form after successful submission
            setSubmissionMessage(`${t('applyWorkshop.sent')} `); // Display a success message
        }, (error) => {
            console.log('Error sending email:', error);
            setSubmissionMessage('Failed to send email: ' + error.text); // Display an error message
        });
    };

    return (
        <section id="contactPage" className="cPage">
            <h1 className="contactPageTitle">{t('contact.title')}</h1>
            <div id="contact">
                <div id="contactContent">
                    <p>{t('contact.address')}</p>
                    <p>{t('contact.phone')}</p>
                    <p>{t('contact.e-mail')}</p>
                </div>
                <div className="contactMap">
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe width="300" height="200"
                            id="gmap_canvas"
                            src="https://maps.google.com/maps?width=300&amp;height=200&amp;hl=en&amp;q=Bajcsy-Zsilinszky%20utca%2063.%20Debrecen+(RK%20Wall%20Art)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    <script type='text/javascript'
                            src='https://embedmaps.com/google-maps-authorization/script.js?id=f8d542d43b4c680a366db64237ae852fa06fe290'></script>
                </div>
            </div>
            <div id="applyWorkshop">
                <h1 className="contactWorkshopTitle">{t("applyWorkshop.title")}</h1>
                <div id="contactWorkshop">
                    <p className="note">{t("applyWorkshop.note")}</p>
                    <form className="contactWorkshopForm" ref={form} onSubmit={handleSubmit}>
                        <input type="text" className="name" placeholder={t("applyWorkshop.name")} name='from_name'
                               onChange={handleChange}/>
                        <input type="number" className="phoneNumber" placeholder={t("applyWorkshop.phoneNumber")}
                               name='from_phoneNumber' onChange={handleChange}/>
                        <input type="text" className="email" placeholder={t("applyWorkshop.e-mail")} name='from_email'
                               onChange={handleChange}/>
                        <input type="text" className="address" placeholder={t("applyWorkshop.address")}
                               name='from_address' onChange={handleChange}/>
                        <input type="text" className="date" placeholder={t("applyWorkshop.date")}
                               name='from_date' onChange={handleChange}/>
                        <textarea name="message" placeholder={t("applyWorkshop.message")} rows={3}
                                  className='msg'></textarea>
                        {submissionMessage && (
                            <div className="submissionMessage">{submissionMessage}</div>
                        )}
                        <button type="submit" className='submitBtn'>{t("applyWorkshop.button")}</button>
                    </form>
                </div>
            </div>
            <div className="links">
                <div className="social">
                    <a href="https://www.facebook.com/profile.php?id=61555170377700" target="_blank"
                       rel="noopener noreferrer">
                        <img src={facebookIcon} alt="Facebook" className="link"/>
                    </a>
                    <a href="https://www.instagram.com/rkwallartdesign/?utm_source=qr" target="_blank"
                       rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" className="link"/>
                    </a>
                </div>
                <div className="image">
                    <img src={logo2} alt="logo" className="logo2"/>
                </div>
                <div className="sponsors">
                    <p className="sponsorPara">{t("sponsors.para")}</p>
                    <img src={trilakLogo} alt="logo" className="logo3"/>
                    <img src={heraLogo} alt="logo" className="logo3"/>
                </div>
            </div>
        </section>
    )
        ;
}

export default Contact;