import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {useTranslation} from "react-i18next";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/zoom';

import './references.css';
// import required modules
import { EffectCoverflow, Pagination, Navigation, Zoom } from 'swiper/modules';

import './references.css';
import img2 from '../../assets/img2.JPG';
import img3 from '../../assets/img3.JPG';
import img4 from '../../assets/img4.JPG';
import img5 from '../../assets/img5.JPG';
import img7 from '../../assets/img7.PNG';
import img8 from '../../assets/img8.jpg';
import img9 from '../../assets/img9.JPG';
import img1 from '../../assets/img1.JPG';
import img11 from '../../assets/img11.JPG';
import img12 from '../../assets/img12.JPG';
import img13 from '../../assets/img13.PNG';
import img14 from '../../assets/img14.JPG';
import img15 from '../../assets/img15.JPG';
import img151 from '../../assets/img15_.jpg';
import img16 from '../../assets/img16.JPG';
import img17 from '../../assets/img17.JPG';
import img18 from '../../assets/img18.JPG';
import img19 from '../../assets/img19.PNG';
import img20 from '../../assets/img20.JPG';
import img21 from '../../assets/img21.JPG';
import img22 from '../../assets/img22.JPG';
import img23 from '../../assets/img23.JPG';
import img24 from '../../assets/img24.jpg';
import img25 from '../../assets/img25.jpg';
import img26 from '../../assets/img26.JPG';
import img27 from '../../assets/img27.JPG';
import img28 from '../../assets/img28.PNG';
import img29 from '../../assets/img29.JPG';
import img30 from '../../assets/img30.JPG';
import img31 from '../../assets/img31.JPG';
import img32 from '../../assets/img32.JPG';
import img33 from '../../assets/img33.JPG';
import img34 from '../../assets/img34.jpg';
import img35 from '../../assets/img35.jpg';
import img36 from '../../assets/img36.jpg';
import img37 from '../../assets/img37.jpg';

const References = () => {
    const { t } = useTranslation();

    return (
        <section id="references">
            <h2 className="referencesTitle">{t('references.title')}</h2>
            <p className="referencesPara">{t('references.para')}</p>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                loop={true}
                zoom={true}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                    scale: 0.30,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation, Zoom]}
                className="mySwiper">
                <SwiperSlide><div className="swiper-zoom-container"><img id={1} src={img1} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={2} src={img2} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={3} src={img3} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={4} src={img4} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={5} src={img5} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={7} src={img7} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={8} src={img8} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={9} src={img9} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={11} src={img11} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={12} src={img12} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={13} src={img13} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={14} src={img14} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={15} src={img15} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={151} src={img151} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={16} src={img16} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={17} src={img17} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={18} src={img18} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={19} src={img19} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={20} src={img20} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={21} src={img21} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={22} src={img22} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={23} src={img23} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={24} src={img24} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={25} src={img25} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={26} src={img26} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={27} src={img27} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={28} src={img28} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={29} src={img29} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={30} src={img30} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={31} src={img31} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={32} src={img32} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={33} src={img33} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={34} src={img34} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={35} src={img35} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={36} src={img36} alt=""/></div></SwiperSlide>
                <SwiperSlide><div className="swiper-zoom-container"><img id={37} src={img37} alt=""/></div></SwiperSlide>
            </Swiper>
        </section>
    );
}

export default References;