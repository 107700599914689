import React from 'react';
import {useTranslation} from "react-i18next";
import './workshop.css';

const Workshop = () => {
    const { t } = useTranslation();

    const tech3Rows = [
        {date: `${t('workshop.workshop3techDate1')}`,start: `${t('workshop.workshop3techStart')}`, num: `${t('workshop.workshop3techDate1FreeSpace')}`},
        {date: `${t('workshop.workshop3techDate2')}`,start: `${t('workshop.workshop3techStart')}`, num: `${t('workshop.workshop3techDate2FreeSpace')}`},
    ]

    const tech5Rows = [
        {date: `${t('workshop.workshop5techDate1')}`,start: `${t('workshop.workshop5techStart')}`, num: `${t('workshop.workshop5techDate1FreeSpace')}`},
        {date: `${t('workshop.workshop5techDate2')}`,start: `${t('workshop.workshop5techStart')}`, num: `${t('workshop.workshop5techDate2FreeSpace')}`},
    ]

    const tech2daysRows = [
       {date: `${t('workshop.workshop2daysDate1')}`,start: `${t('workshop.workshop5techStart')}`, num: `${t('workshop.workshop2daysDate1FreeSpace')}`},
       {date: `${t('workshop.workshop2daysDate2')}`,start: `${t('workshop.workshop5techStart')}`, num: `${t('workshop.workshop2daysDate2FreeSpace')}`},
    ]

    const seasonRows = [
        ]

    return (
        <section id="workshop">
            <span className="workshopTitle">Workshop</span>
            <div className="workshopContent">
                <p>{t('workshop.startPara')}</p>
                <p>{t('workshop.zeroPara')}</p>
                <p>{t('workshop.firstPara')}</p>
                <p>{t('workshop.secondPara')}</p>
                <p>{t('workshop.thirdPara')}</p>
                <p>{t('workshop.fourthPara')}</p>
                <p><b>{t('workshop.fifthPara')}</b></p>
                <p>{t('workshop.sixthPara')}</p>
                <p>{t('workshop.seventhPara')}<b>{t('workshop.seventhParaBold')}</b></p>
                <p>{t('workshop.eighthPara')}</p>
                <p>{t('workshop.ninthPara')}</p>
                <p><b>{t('workshop.tenthPara')}</b></p>
                <p>{t('workshop.11thPara')}</p>
                <p>{t('workshop.12thPara')}</p>
                <p>{t('workshop.13thPara')}</p>
                <p>{t('workshop.14thPara')}<b>{t('workshop.14thParaBold')}</b>{t('workshop.14thPara2')}</p>
                <p>{t('workshop.plus')}</p>
                <p>{t('workshop.15thPara')}</p>
                <p><b>{t('workshop.16thPara')}</b></p>
                <p className="title">{t('workshop.DescriptionTitle')}</p>
                <p className="subtitle">{t('workshop.17thParaTitle')}</p>
                <p>{t('workshop.17thPara')}</p>
                <p>{t('workshop.18thPara')}</p>
                <p className="subtitle">{t('workshop.19thParaTitle')}</p>
                <p>{t('workshop.19thPara')}</p>
                <p>{t('workshop.20thPara')}</p>
                <p className="subtitle">{t('workshop.21thParaTitle')}</p>
                <p>{t('workshop.21thPara')}</p>
                <p>{t('workshop.22thPara')}</p>
                <p>{t('workshop.22thPara1')}</p>
                <p>{t('workshop.22thPara2')}</p>
                <p>{t('workshop.22thPara3')}</p>
                <p>{t('workshop.22thPara4')}</p>
                <p>{t('workshop.22thPara5')}</p>
                <p>{t('workshop.22thPara6')}</p>
                <p>{t('workshop.23thPara')}</p>
            </div>
            <span className="workshopSubtitle">{t('workshop.subtitle')}</span>
            <div className="workshopDatePrice">
                <div className="tableContainer">
                    <div className="table1">
                        <p className="workshopPrice">{t('workshop.workshop')}</p>
                        <table className="tb">
                            <thead>
                            <tr>
                                <td>{t('workshop.workshopDate')}</td>
                                <td>{t('workshop.workshopTime')}</td>
                                <td>{t('workshop.workshopFreeSpace')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {tech3Rows.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.start}</td>
                                    <td>{row.num}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="table2">
                        <p className="workshopPrice">{t('workshop.workshop2')}</p>
                        <table className="tb">
                            <thead>
                            <tr>
                                <td>{t('workshop.workshopDate')}</td>
                                <td>{t('workshop.workshopTime')}</td>
                                <td>{t('workshop.workshopFreeSpace')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {tech5Rows.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.start}</td>
                                    <td>{row.num}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="table3">
                <p className="workshopPrice">{t('workshop.workshop3')}</p>
                <table className="tb">
                    <thead>
                    <tr>
                        <td>{t('workshop.workshopDate')}</td>
                        <td>{t('workshop.workshopTime')}</td>
                        <td>{t('workshop.workshopFreeSpace')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {tech2daysRows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.start}</td>
                            <td>{row.num}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="table3">
                <p className="workshopPrice">{t('workshop.workshop4')}</p>
                <table className="tb">
                    <thead>
                    <tr>
                        <td>{t('workshop.workshopDate')}</td>
                        <td>{t('workshop.workshopTime')}</td>
                        <td>{t('workshop.workshopFreeSpace')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {seasonRows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.start}</td>
                            <td>{row.num}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <p className="data">{t('workshop.data')}</p>
            <p className="data">{t('workshop.data1')}</p>
            <p className="data">{t('workshop.data2')}</p>
            <p className="data">{t('workshop.data3')}</p>
            <button className="applyWorkshopButton" onClick={() => {
                document.getElementById('applyWorkshop').scrollIntoView({behavior: 'smooth'});
            }}>{t('workshop.applyWorkshopButton')}
            </button>
        </section>
    );
}

export default Workshop;